.nav-bar {
  background-color: var(--champagne);
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-logo-and-button {
  display: flex;
  justify-content: space-between;
  width: inherit;
  height: inherit;
  margin: 0 2em;
}

.logo-container {
  margin: 1em 0.5em;
}

.logo {
  font-size: 10px;
  margin-top: 0.05em;
}

.nav-links {
  display: none;
  /* width: 0; */
}

.nav-bar a {
  text-decoration: none;
  color: inherit;
}

.nav-bar .active {
  color: red;
}

.nav-bar .logo {
  color: var(--brown);
}

.nav-links.show-links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: end;
  /* width: 100; */
}

.overlay {
  display: none;
  height: auto;
  width: 100;
  background-color: var(--champagne);
  position: fixed;
  overflow-x: hidden;
  transition: 0.5s;
  padding-bottom: 1em;
}

.overlay.show-links{
  display: flex;
  text-align: end;
  width: 100%;
  gap: 20px;
}

.overlay-content {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 79px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.overlay a {
  text-decoration: none;
  font-size: 36px;
  /* color: #000000; */
  color: inherit;
  display: block;
  transition: 0.3s;
}

#myNav a.active {
  color: var(--gold);
}

.menu-btn {
  border: none;
  background-color: inherit;
  color: var(--gold);
}

/* .nav-links:hover {
  background-color: #000000;
} */

@media screen and (min-width: 768px) {
  .nav-logo-and-button {
    flex-direction: row;
  }

  .nav-bar {
    justify-content: space-around;
  }

  .nav-links {
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .menu-btn {
    display: none;
  }
}